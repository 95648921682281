<template>
    <v-container fluid>
        <v-form @submit.prevent="getPages('search')">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'page.create'}"
                   color="indigo" fab fixed top right class="v-btn--add-form-top white--text">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="10">
                                <v-subheader class="headline">{{ $t('search_page') }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                                <v-btn :to="{name: 'page.create'}" class="white--text" color="indigo">
                                    {{ $t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="10">
                                <ValidationProvider ref="title" rules="min:3"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="title" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-pagepaper-variant-outline"
                                                  :label="$t('page_title')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="2">
                                <v-select v-model="active" :items="activeItems" :disabled="loading"
                                          item-text="name" item-value="id" prepend-icon="mdi-check"
                                          :label="$t('active')"></v-select>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-dialog ref="datePageFrom" v-model="datePageFrom" width="290px">
                                    <v-date-picker ref="pickerTourFrom" v-model="dateFrom"
                                                   :max="dateTo ? $moment(dateTo).format('YYYY-MM-DD') : $moment().add(10, 'y').format('YYYY-MM-DD')"
                                                   :locale="lang" @change="datePageFrom = false"></v-date-picker>
                                </v-dialog>
                                <ValidationProvider ref="dateFrom" rules="date_format"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="dateFrom" :error-messages="errors"
                                                  :disabled="loading" :label="$t('date_from')"
                                                  prepend-icon="mdi-calendar" color="primary" readonly
                                                  @click.stop="datePageFrom = !datePageFrom"
                                                  clearable @click:clear="dateFrom = null"></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-dialog ref="datePageTo" v-model="datePageTo" width="290px">
                                    <v-date-picker ref="pickerTourTo" v-model="dateTo"
                                                   :min="dateFrom ? $moment(dateFrom).format('YYYY-MM-DD') : $moment().subtract(10, 'y').format('YYYY-MM-DD')"
                                                   :locale="lang" @change="datePageTo = false"></v-date-picker>
                                </v-dialog>
                                <ValidationProvider ref="dateTo" rules="date_format"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="dateTo" :error-messages="errors" :disabled="loading"
                                                  :label="$t('date_to')" prepend-icon="mdi-calendar"
                                                  color="primary" readonly clearable
                                                  @click.stop="datePageTo = !datePageTo"
                                                  @click:clear="dateTo = null"></v-text-field>
                                </ValidationProvider>
                            </v-col>

                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)" hide-details
                                          dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text>
                <v-data-table :headers="headers" :items="pageItems" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalPages" :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.title="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.title }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </div>
                    </template>
                    <template v-slot:item.active="{ item }">
                        {{ item.active ? $t('yes') : $t('no')}}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="mr-2" @click="editPage(item)" :title="$t('edit')">
                                    mdi-square-edit-outline
                                </v-icon>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" :disabled="item.deleted" @click="deletePage(item)" icon>
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ item.deleted ? $t('remotely') : $t('delete') }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePage"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex"

    export default {
        name: "Pages",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                title: null,
                sortBy: "created_at",
                sortDir: true,
                loading: false,
                options: {},
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePage: 5,
                totalPages: 0,
                pageItems: [],
                dateFrom: null,
                datePageFrom: false,
                dateTo: null,
                datePageTo: false,
                headers: [
                    {
                        text: this.$t('page_title'),
                        align: "left",
                        sortable: true,
                        value: "title"
                    },
                    {
                        text: this.$t('menu_slug'),
                        align: "center",
                        sortable: false,
                        value: "slug"
                    },
                    {
                        text: this.$t('date_and_time'),
                        align: "center",
                        sortable: true,
                        value: "created_at"
                    },
                    {
                        text: this.$t('active'),
                        align: "center",
                        sortable: true,
                        value: "active",
                        width: 120,
                    },
                    {
                        text: this.$t('actions'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 120,
                    },
                ],
                itemYN: [
                    {
                        id: 1,
                        name: this.$t('yes'),
                    },
                    {
                        id: 0,
                        name: this.$t('no'),
                    },
                ],
                active: 1,
                activeItems: [
                    {
                        id: null,
                        name: this.$t('all')
                    },
                    {
                        id: 1,
                        name: this.$t('yes')
                    },
                    {
                        id: 0,
                        name: this.$t('no')
                    }
                ],
            }
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
        },
        watch: {
            options: {
                handler() {
                    this.getPages()
                },
                deep: false
            }
        },
        methods: {
            editPage(item) {
                this.$router.push({
                    name: 'page.edit',
                    params: {
                        id: item.id
                    }
                })
            },
            async getPages(type) {
                this.loading = true
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options

                let params = {}

                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0]
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc'
                }
                if (page !== undefined) {
                    if (type === 'search') {
                        params.page = 1
                    } else {
                        params.page = page
                    }
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage
                }
                if (this.title) {
                    params.title = this.title
                }
                if (this.dateFrom) {
                    params.date_from = this.dateFrom
                }
                if (this.dateTo) {
                    params.date_to = this.dateTo
                }
                params.active = this.active
                await this.$http
                    .get("admin/page", {
                        params: params,
                    })
                    .then(res => {
                        this.pageItems = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalPages = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                    })
                    .catch(err => {
                        this.pageItems = []
                        this.totalPages = 0
                        this.$toastr.error(this.$t('failed_to_get_list_page'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async deletePage(item) {
                if (confirm(this.$t('delete_page'))) {
                    var _this = this
                    this.loading = true
                    await this.$http
                        .delete(`admin/page/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('page_has_been_deleted'))
                            this.getPages()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('page_has_not_been_deleted'))
                            if (err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            }
        }
    }
</script>
